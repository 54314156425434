import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import './ClaudeScenarioGenerator.css';

function ClaudeScenarioGenerator({ 
  mode = 'generate', 
  supabase, 
  onGenerated, 
  onClose 
}) {
  const { user } = useAuth();  // Only get user since session is not in AuthContext
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [statusMessage, setStatusMessage] = useState('');

  const [formData, setFormData] = useState({
    scenario_topic: '',
    difficulty: 'medium',
    number_of_questions: 8,
    category: '',
    complexity: 'moderate',
    role: 'emt',
    context_details: '',
    is_generated: false,
    generation_status: 'pending',
    error_message: ''
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setStatusMessage('Starting scenario generation...');
  
    try {
      if (!user) {
        throw new Error('User not authenticated');
      }

      console.log('Creating script entry...');
      const { data: scriptData, error: scriptError } = await supabase
        .from('ClaudeScenarioScript')
        .insert([{
          ...formData,
          created_by: user.email,
          created_at: new Date().toISOString()
        }])
        .select()
        .single();
  
      if (scriptError) {
        throw new Error(`Database Error: ${scriptError.message}`);
      }
  
      setStatusMessage('Requesting scenario generation...');
  
      const requestBody = {
        scriptId: scriptData.id,
        scenario: {
          topic: formData.scenario_topic,
          category: formData.category,
          difficulty: formData.difficulty,
          complexity: formData.complexity,
          role: formData.role,
          numberOfQuestions: formData.number_of_questions,
          contextDetails: formData.context_details
        },
        user: {
          email: user.email,
          fullName: user.fullName
        }
      };

      // Call the function without session token
// Call the function without session token
// Inside handleSubmit function in ClaudeScenarioGenerator.js
const { data: functionData, error: functionError } = await supabase.functions.invoke(
  'generate-scenario',
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(requestBody)  // Explicitly stringify the body
  }
);

// Add more logging
console.log('Request body:', JSON.stringify(requestBody, null, 2));
if (functionError) {
  console.error('Detailed function error:', functionError);
  console.error('Function error response:', functionError.response);
}
  
      if (functionError) {
        console.error('Function Error:', functionError);
        throw new Error(`Scenario generation failed: ${JSON.stringify(functionError)}`);
      }
  
      if (!functionData) {
        throw new Error('No response data received from generation function');
      }
  
      setStatusMessage('Scenario generated successfully!');
      if (onGenerated) {
        onGenerated(functionData);
      }
      setIsOpen(false);
  
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      if (error.message.includes('authenticated')) {
        navigate('/');
      } else {
        setError(error.message || 'An unexpected error occurred');
        setStatusMessage('Error occurred during generation');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = useCallback((field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  }, []);

  React.useEffect(() => {
    // Check for user data on mount
    if (!user) {
      const storedUser = localStorage.getItem('userData');
      if (!storedUser) {
        navigate('/');
      }
    }
  }, [user, navigate]);

  return (
    <div className="scenario-generator-container">
      <button
        onClick={() => {
          if (!user) {
            navigate('/');
            return;
          }
          setIsOpen(true);
        }}
        className="generate-button"
        disabled={loading}
      >
        {mode === 'csv' ? 'Generate Scenario as CSV' : 'Generate New Scenario'}
      </button>

      {isOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Generate EMT Scenario</h2>
              <button
                onClick={() => setIsOpen(false)}
                className="close-button"
                aria-label="Close"
              >
                ×
              </button>
            </div>

            <form onSubmit={handleSubmit} className="scenario-form">
              {error && (
                <div className="error-message">
                  {error}
                </div>
              )}

              <div className="form-group">
                <label htmlFor="scenario_topic">Scenario Topic</label>
                <input
                  id="scenario_topic"
                  type="text"
                  value={formData.scenario_topic}
                  onChange={(e) => handleInputChange('scenario_topic', e.target.value)}
                  placeholder="e.g., Head Trauma"
                  required
                  className="form-input"
                />
              </div>

              <div className="form-group">
                <label htmlFor="category">Category</label>
                <input
                  id="category"
                  type="text"
                  value={formData.category}
                  onChange={(e) => handleInputChange('category', e.target.value)}
                  placeholder="e.g., Trauma"
                  required
                  className="form-input"
                />
              </div>

              <div className="form-group">
                <label htmlFor="difficulty">Difficulty</label>
                <select
                  id="difficulty"
                  value={formData.difficulty}
                  onChange={(e) => handleInputChange('difficulty', e.target.value)}
                  className="form-select"
                >
                  <option value="easy">Easy</option>
                  <option value="medium">Medium</option>
                  <option value="hard">Hard</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="complexity">Complexity</label>
                <select
                  id="complexity"
                  value={formData.complexity}
                  onChange={(e) => handleInputChange('complexity', e.target.value)}
                  className="form-select"
                >
                  <option value="simple">Simple</option>
                  <option value="moderate">Moderate</option>
                  <option value="complex">Complex</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="role">Target Role</label>
                <select
                  id="role"
                  value={formData.role}
                  onChange={(e) => handleInputChange('role', e.target.value)}
                  className="form-select"
                >
                  <option value="paramedic">Paramedic</option>
                  <option value="emt">EMT</option>
                  <option value="emta">EMTA</option>
                  <option value="medical-student">Medical Student</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="number_of_questions">Number of Questions</label>
                <input
                  id="number_of_questions"
                  type="number"
                  min="1"
                  max="20"
                  value={formData.number_of_questions}
                  onChange={(e) => handleInputChange('number_of_questions', parseInt(e.target.value))}
                  required
                  className="form-input"
                />
              </div>

              <div className="form-group">
                <label htmlFor="context_details">Additional Context (Optional)</label>
                <textarea
                  id="context_details"
                  value={formData.context_details}
                  onChange={(e) => handleInputChange('context_details', e.target.value)}
                  placeholder="Enter any additional requirements or details"
                  className="form-textarea"
                />
              </div>

              {statusMessage && (
                <div className="status-message">{statusMessage}</div>
              )}

              <button
                type="submit"
                disabled={loading}
                className="submit-button"
              >
                {loading ? "Generating..." : "Generate Scenario"}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default ClaudeScenarioGenerator;