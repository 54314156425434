import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import { useAuth } from '../../contexts/AuthContext';
import './LoginScreen.css';

function LoginScreen() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
       
      const { data, error } = await supabase
        .from('user_details')
        .select('email, fullName')
        .eq('email', email)
        .single();

      if (error) {
        console.error('Database error:', error);
        throw error;
      }

      if (data) {
         
        login(data.email, data.fullName);
        
         
        try {
          await supabase
            .from('audit_log')
            .insert([{
              user_email: data.email,
              action: 'login',
              details: `User ${data.fullName} logged in`
            }]);
        } catch (auditError) {
          console.error('Failed to log audit:', auditError);
           
        }

        navigate('/home');
      } else {
        setError('Email not found or not authorized');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('Invalid email or not authorized');
    }
  };

  return (
    <div className="login-screen">
      <div className="login-container">
        <h1>EMTeams Scenario Review</h1>
        <form onSubmit={handleSubmit} className="login-form">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
              className="email-input"
            />
          </div>
          <button type="submit" className="login-button">
            Login
          </button>
          {error && <p className="error-message">{error}</p>}
        </form>
      </div>
    </div>
  );
}

export default LoginScreen;