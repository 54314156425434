import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import { useAuth } from '../../contexts/AuthContext';
import './HomeScreen.css';

function HomeScreen() {
  const [scenarios, setScenarios] = useState([]);
  const [currentScenario, setCurrentScenario] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('All Statuses');
  const [editMode, setEditMode] = useState(false);
  const [error, setError] = useState(null);
  const [isAddingQuestion, setIsAddingQuestion] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [currentResultIndex, setCurrentResultIndex] = useState(0);
  const [updatedStatus, setUpdatedStatus] = useState('');
  const [tagError, setTagError] = useState('');
  
  const { user, logout } = useAuth();

  const fieldsToHide = ['p', 'r', 'bp', 'spo2', 'bg', 'scenario_table_id'];



  const PREDEFINED_STATUSES = [
    null,
    "Reviewed",
    "Bring to Jim's Attention",
    "Bring to Kevin's Attention",
    "Bring to Lhexi's Attention",
    "Needs Additional Question(s)",
    "Needs Additional Review",
    "Needs Image File",
    "Question should be eliminated",
    "Scenario should be eliminated"
  ];

  const largeTextFields = [
    'patientreport', 'question', 'option1', 'option2', 'option3', 'option4',
    'explanation1', 'explanation2', 'explanation3', 'explanation4', 'fulltext',
    'tags', 'meds', 'exam', 'physicalexam', 'comments'
  ];
  const mediumTextFields = ['image'];

  useEffect(() => {
    fetchScenarios();
    fetchStatuses();
  }, []);

  useEffect(() => {
    if (currentScenario) {
      setUpdatedStatus(currentScenario.status || '');
    }
  }, [currentScenario]);

  const parseTagsFromString = (tagsString) => {
    if (!tagsString) return [];
    return tagsString.split(',')
      .map(tag => tag.trim())
      .filter(tag => tag !== '')
      .map(tag => tag.startsWith('#') ? tag : `#${tag}`);
  };

  const formatTagsForDisplay = (tagsArray) => {
    if (!tagsArray || !Array.isArray(tagsArray)) return '';
    return tagsArray.join(', ');
  };

  const getFieldLabel = (key) => {
    const labels = {
      patientreport: 'Patient Report',
      question: 'Question',
      option1: 'Option 1',
      option2: 'Option 2',
      option3: 'Option 3',
      option4: 'Option 4',
      explanation1: 'Explanation 1',
      explanation2: 'Explanation 2',
      explanation3: 'Explanation 3',
      explanation4: 'Explanation 4',
      fulltext: 'Full Text',
      tags: 'Tags',
      meds: 'Medications',
      exam: 'Exam',
      physicalexam: 'Physical Exam',
      comments: 'Comments',
      scenarioid: 'Scenario ID',
      order: 'Order',
      category: 'Category',
      diagnosiscategory: 'Diagnosis Category',
      title: 'Title',
      status: 'Status',
      answer: 'Answer',
      image: 'Image',
      dateadded: 'Date Added'
    };
    return labels[key] || key.charAt(0).toUpperCase() + key.slice(1);
  };

  async function fetchScenarios(status = 'All Statuses', search = '') {
    try {
      let query = supabase.from('scenarios').select('*');
      
      if (status !== 'All Statuses') {
        query = query.eq('status', status);
      }
      
      if (search) {
        query = query.or(
          `title.ilike.%${search}%,` +
          `patientreport.ilike.%${search}%,` +
          `question.ilike.%${search}%,` +
          `scenarioid.eq.${parseInt(search) || 0}`
        );
      }
      
      query = query.order('scenarioid', { ascending: true }).order('order', { ascending: true });
      
      const { data, error } = await query;
      if (error) throw error;

      const formattedData = data.map(scenario => ({
        ...scenario,
        tags: formatTagsForDisplay(scenario.tags)
      }));

      setScenarios(formattedData);
      setSearchResults(formattedData);
      setCurrentResultIndex(0);
      if (formattedData.length > 0) setCurrentScenario(formattedData[0]);
    } catch (error) {
      console.error('Error fetching scenarios:', error.message);
      setError(`Failed to fetch scenarios: ${error.message}`);
    }
  }

  async function fetchStatuses() {
    try {
      const { data, error } = await supabase
        .from('scenarios')
        .select('status')
        .not('status', 'is', null);
      
      if (error) throw error;

       
      const existingStatuses = [...new Set(data.map(item => item.status))];

       
      const combinedStatuses = [...new Set([...PREDEFINED_STATUSES, ...existingStatuses])];

       
       
       
       
      const sortedStatuses = combinedStatuses.sort((a, b) => {
        if (a === null) return -1;
        if (b === null) return 1;
        if (a === "Reviewed") return -1;
        if (b === "Reviewed") return 1;
        return a.localeCompare(b);
      });

      setStatuses(['All Statuses', ...sortedStatuses]);
    } catch (error) {
      console.error('Error fetching statuses:', error.message);
      setError(`Failed to fetch statuses: ${error.message}`);
    }
  }

  function handleStatusChange(e) {
    const status = e.target.value;
    setSelectedStatus(status);
    fetchScenarios(status, searchTerm);
  }

  function handleScenarioChange(e) {
    const selectedScenario = scenarios.find(s => s.id.toString() === e.target.value);
    setCurrentScenario(selectedScenario);
    setEditMode(false);
    setIsAddingQuestion(false);
  }

  function handleInputChange(e) {
    const { name, value } = e.target;
    
    if (name === 'tags') {
      setTagError('');
    }
    
    setCurrentScenario(prev => ({ ...prev, [name]: value }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (isAddingQuestion) {
      await saveNewQuestion();
    } else {
      await updateScenario(currentScenario);
    }
  }

  function handleSearch(e) {
    e.preventDefault();
    const sanitizedSearch = searchTerm.replace(/[%;]/g, '');
    fetchScenarios(selectedStatus, sanitizedSearch);
  }

  function handleNextResult() {
    if (currentResultIndex < searchResults.length - 1) {
      const nextIndex = currentResultIndex + 1;
      setCurrentResultIndex(nextIndex);
      setCurrentScenario(searchResults[nextIndex]);
    }
  }

  function handlePrevious() {
    const currentIndex = scenarios.findIndex(s => s.id === currentScenario.id);
    if (currentIndex > 0) {
      setCurrentScenario(scenarios[currentIndex - 1]);
      setIsAddingQuestion(false);
      setEditMode(false);
    }
  }

  function handleNext() {
    const currentIndex = scenarios.findIndex(s => s.id === currentScenario.id);
    if (currentIndex < scenarios.length - 1) {
      setCurrentScenario(scenarios[currentIndex + 1]);
      setIsAddingQuestion(false);
      setEditMode(false);
    }
  }

  function toggleEditMode() {
    setEditMode(!editMode);
  }

  async function handleStatusUpdate() {
    if (!currentScenario) return;

    try {
      const now = new Date().toISOString();
      const statusChangeComment = `Status changed to ${updatedStatus} by ${user.email} on ${now}`;
      const updatedComments = currentScenario.comments
        ? `${currentScenario.comments}\n${statusChangeComment}`
        : statusChangeComment;

      const { data, error } = await supabase
        .from('scenarios')
        .update({ 
          status: updatedStatus,
          comments: updatedComments
        })
        .eq('id', currentScenario.id)
        .select();

      if (error) throw error;

      setCurrentScenario({
        ...data[0],
        tags: formatTagsForDisplay(data[0].tags)
      });
      fetchScenarios(selectedStatus, searchTerm);
      
       
      await logAudit('update_status', `Updated status for scenario ${currentScenario.id} to ${updatedStatus}`);
    } catch (error) {
      console.error('Error updating status:', error.message);
      setError(`Failed to update status: ${error.message}`);
    }
  }

  async function updateScenario(updatedScenario) {
    try {
      let tagsArray = [];
      if (updatedScenario.tags) {
        tagsArray = parseTagsFromString(updatedScenario.tags);
        
        const invalidTags = tagsArray.filter(tag => !tag.startsWith('#'));
        if (invalidTags.length > 0) {
          setTagError('All tags must start with # symbol');
          return;
        }
      }

      const { id, ...scenarioWithoutId } = {
        ...updatedScenario,
        tags: tagsArray
      };
      
      const { data, error } = await supabase
        .from('scenarios')
        .update(scenarioWithoutId)
        .eq('id', id)
        .select();

      if (error) throw error;
      
      setCurrentScenario({
        ...data[0],
        tags: formatTagsForDisplay(data[0].tags)
      });
      setEditMode(false);
      fetchScenarios(selectedStatus, searchTerm);
      
       
      await logAudit('update_scenario', `Updated scenario ${id}`);
    } catch (error) {
      console.error('Error updating scenario:', error.message);
      setError(`Failed to update scenario: ${error.message}`);
    }
  }

  function handleAddQuestion() {
    if (!currentScenario) return;

    const newScenarioId = currentScenario.scenarioid;
    const newOrder = Math.max(...scenarios
      .filter(s => s.scenarioid === newScenarioId)
      .map(s => s.order)) + 1;

    const newQuestion = {
      scenarioid: newScenarioId,
      order: newOrder,
      category: currentScenario.category,
      diagnosiscategory: currentScenario.diagnosiscategory,
      title: currentScenario.title,
      patientreport: currentScenario.patientreport,
      status: 'New',
      question: '',
      option1: '',
      option2: '',
      option3: '',
      option4: '',
      explanation1: '',
      explanation2: '',
      explanation3: '',
      explanation4: '',
      answer: '',
      fulltext: '',
      image: '',
      dateadded: new Date().toISOString().split('T')[0],
      tags: '',
      meds: '',
      exam: '',
      physicalexam: '',
      comments: ''
    };

    setCurrentScenario(newQuestion);
    setIsAddingQuestion(true);
    setEditMode(true);
  }

  async function saveNewQuestion() {
    try {
      const { id, ...questionData } = currentScenario;
      
      const tagsArray = parseTagsFromString(questionData.tags);
      
      const questionToSave = {
        ...questionData,
        tags: tagsArray
      };
      
      const { data, error } = await supabase
        .from('scenarios')
        .insert(questionToSave)
        .select();

      if (error) throw error;

      const formattedData = {
        ...data[0],
        tags: formatTagsForDisplay(data[0].tags)
      };

      setScenarios([...scenarios, formattedData]);
      setCurrentScenario(formattedData);
      setIsAddingQuestion(false);
      setEditMode(false);
      fetchScenarios(selectedStatus, searchTerm);
      
       
      await logAudit('add_question', `Added new question to scenario ${data[0].scenarioid}`);
    } catch (error) {
      console.error('Error adding new question:', error.message);
      setError(`Failed to add new question: ${error.message}`);
    }
  }

  async function logAudit(action, details) {
    try {
      const { error } = await supabase
        .from('audit_log')
        .insert({ user_email: user.email, action, details });
      if (error) throw error;
    } catch (error) {
      console.error('Error logging audit:', error.message);
    }
  }

  function getExplanationStyle(key) {
    if (!currentScenario || !currentScenario.answer) return '';
    const explanationNumber = key.replace('explanation', '');
    return explanationNumber === String(currentScenario.answer) ? 'correct' : 'incorrect';
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="home-screen">
      <div className="header-container">
        <h1>Question Review Form</h1>
        <div className="user-controls">
          <span className="user-info">Welcome, {user?.fullName}</span>
          <Link to="/datasheet" className="btn btn-primary">Switch to Datasheet View</Link>
          <button className="btn btn-danger" onClick={logout}>Logout</button>
        </div>
      </div>

      <div className="controls-section">
        <div className="filter-row">
          <div className="filter-group">
            <label>Filter by Status:</label>
            <select onChange={handleStatusChange} value={selectedStatus}>
              {statuses.map(status => (
                <option key={status} value={status}>
                  {status === null ? '(No Status)' : status}
                </option>
              ))}
            </select>
          </div>
          <div className="filter-group">
            <label>Select Question:</label>
            <select onChange={handleScenarioChange} value={currentScenario?.id}>
              {scenarios.map(scenario => (
                <option key={scenario.id} value={scenario.id}>
                  {`${scenario.scenarioid}-${scenario.order}`}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="search-container">
          <form onSubmit={handleSearch} className="search-form">
            <input
              type="text"
              placeholder="Search by scenarioId, title, patient report, or question"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button type="submit" className="btn btn-primary">Search</button>
          </form>
          {searchResults.length > 0 && (
            <div className="search-results">
              <p>Showing result {currentResultIndex + 1} of {searchResults.length}</p>
              {currentResultIndex < searchResults.length - 1 && (
                <button className="btn btn-secondary" onClick={handleNextResult}>
                  Next Result
                </button>
              )}
            </div>
          )}
        </div>

        <div className="navigation-buttons">
          <button 
            className="btn btn-secondary" 
            onClick={handlePrevious} 
            disabled={scenarios.indexOf(currentScenario) === 0}
          >
            Previous
          </button>
          <button 
            className="btn btn-secondary" 
            onClick={handleNext} 
            disabled={scenarios.indexOf(currentScenario) === scenarios.length - 1}
          >
            Next
          </button>
          <button className="btn btn-info" onClick={handleAddQuestion}>
            Add a Question
          </button>
        </div>
      </div>

      {currentScenario && (
        <div className="scenario-container">
          <div className="scenario-header">
            <h2>{isAddingQuestion ? 'Add New Question' : 'Scenario Details'}</h2>
            <div className="current-status">
              Current Status: <span>{currentScenario.status || 'No Status'}</span>
            </div>
          </div>

          <div className="scenario-controls">
            <button 
              className="btn btn-secondary" 
              onClick={toggleEditMode}
            >
              {editMode ? 'Cancel Edit' : 'Edit'}
            </button>
            <div className="status-update">
              <select 
                value={updatedStatus === null ? '' : updatedStatus} 
                onChange={(e) => setUpdatedStatus(e.target.value === '' ? null : e.target.value)}
              >
                {statuses
                  .filter(status => status !== 'All Statuses')
                  .map(status => (
                    <option key={status} value={status || ''}>
                      {status === null ? '(No Status)' : status}
                    </option>
                  ))}
              </select>
              <button className="btn btn-success" onClick={handleStatusUpdate}>
                Update Status
              </button>
            </div>
            {(editMode || isAddingQuestion) && (
              <button className="btn btn-success" onClick={handleSubmit}>
                Save Changes
              </button>
            )}
          </div>

          <form className="scenario-form" onSubmit={handleSubmit}>
            <div className="id-order-row">
              <div className="form-group">
                <label>Scenario ID:</label>
                <input
                  type="text"
                  name="scenarioid"
                  value={currentScenario.scenarioid || ''}
                  disabled
                  className="form-control readonly"
                />
              </div>
              <div className="form-group">
                <label>Order:</label>
                <input
                  type="text"
                  name="order"
                  value={currentScenario.order || ''}
                  disabled
                  className="form-control readonly"
                />
              </div>
            </div>

            {currentScenario && Object.entries(currentScenario)
              .filter(([key]) => !fieldsToHide.includes(key.toLowerCase()))
              .map(([key, value]) => {
                if (key === 'scenarioid' || key === 'order') return null;
                
                return (
                  <div key={key} className={`form-group ${
                    largeTextFields.includes(key) ? 'large-field' : ''
                  } ${mediumTextFields.includes(key) ? 'medium-field' : ''}`}>
                    <label>{getFieldLabel(key)}:</label>
                    {key === 'tags' ? (
                      <div className="tags-input-container">
                        <textarea
                          name={key}
                          value={value || ''}
                          onChange={handleInputChange}
                          readOnly={!editMode && !isAddingQuestion}
                          className={`form-control ${largeTextFields.includes(key) ? 'large-text-field' : 'medium-text-field'}`}
                          placeholder="Enter tags starting with # and separated by commas"
                        />
                        {tagError && <div className="tag-error">{tagError}</div>}
                        <div className="tag-help">
                          Format: #TagName, #AnotherTag (start with # and separate with commas)
                        </div>
                      </div>
                    ) : largeTextFields.includes(key) || mediumTextFields.includes(key) ? (
                      <textarea
                        name={key}
                        value={value || ''}
                        onChange={handleInputChange}
                        readOnly={!editMode && !isAddingQuestion}
                        className={`form-control ${
                          largeTextFields.includes(key) ? 'large-text-field' : 'medium-text-field'
                        } ${key.startsWith('explanation') ? `explanation-${getExplanationStyle(key)}` : ''}`}
                      />
                    ) : (
                      <input
                        type={key === 'dateadded' ? 'date' : 'text'}
                        name={key}
                        value={value || ''}
                        onChange={handleInputChange}
                        readOnly={!editMode && !isAddingQuestion}
                        className="form-control"
                      />
                    )}
                  </div>
                );
              })}
          </form>
        </div>
      )}
    </div>
  );
}

export default HomeScreen;