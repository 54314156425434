import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import { useAuth } from '../../contexts/AuthContext';
import ClaudeScenarioGenerator from '../ClaudeScenarioGenerator/ClaudeScenarioGenerator';
import './DatasheetView.css';

function DatasheetView() {
  const [isPasting, setIsPasting] = useState(false);
  const [pasteSuccess, setPasteSuccess] = useState(false);
  const [scenarios, setScenarios] = useState([]);
  const [newScenario, setNewScenario] = useState({});
  const [error, setError] = useState(null);
  const [tagError, setTagError] = useState('');
  const tableScrollRef = useRef(null);
  const [canScroll, setCanScroll] = useState({
    left: false,
    right: false,
    up: false,
    down: false
  });
  
  const { user, logout } = useAuth();

  const columns = [
    { name: 'scenarioid', label: 'Scenario ID', width: '100px' },
    { name: 'order', label: 'Order', width: '70px' },
    { name: 'category', label: 'Category', width: '150px' },
    { name: 'diagnosiscategory', label: 'Diagnosis Category', width: '150px' },
    { name: 'title', label: 'Title', width: '200px' },
    { name: 'patientreport', label: 'Patient Report', width: '300px' },
    { name: 'question', label: 'Question', width: '300px' },
    { name: 'option1', label: 'Option 1', width: '200px' },
    { name: 'option2', label: 'Option 2', width: '200px' },
    { name: 'option3', label: 'Option 3', width: '200px' },
    { name: 'option4', label: 'Option 4', width: '200px' },
    { name: 'answer', label: 'Answer', width: '70px' },
    { name: 'explanation1', label: 'Explanation 1', width: '400px' },
    { name: 'explanation2', label: 'Explanation 2', width: '400px' },
    { name: 'explanation3', label: 'Explanation 3', width: '400px' },
    { name: 'explanation4', label: 'Explanation 4', width: '400px' },
    { name: 'userrole', label: 'User Role', width: '150px' },
    { name: 'questiondifficulty', label: 'Question Difficulty', width: '150px' },
    { name: 'status', label: 'Status', width: '150px' },
    { name: 'image', label: 'Image', width: '150px' },
    { name: 'tags', label: 'Tags', width: '150px' },
    { name: 'meds', label: 'Medications', width: '200px' },
    { name: 'exam', label: 'Exam', width: '200px' },
    { name: 'physicalexam', label: 'Physical Exam', width: '200px' },
    { name: 'comments', label: 'Comments', width: '300px' }
  ];

  useEffect(() => {
    fetchScenarios();
  }, []);

  useEffect(() => {
    const container = tableScrollRef.current;
    if (!container) return;

    const checkScrollability = () => {
      setCanScroll({
        left: container.scrollLeft > 0,
        right: container.scrollLeft < (container.scrollWidth - container.clientWidth),
        up: container.scrollTop > 0,
        down: container.scrollTop < (container.scrollHeight - container.clientHeight)
      });
    };

    container.addEventListener('scroll', checkScrollability);
    window.addEventListener('resize', checkScrollability);
    checkScrollability();

    return () => {
      container.removeEventListener('scroll', checkScrollability);
      window.removeEventListener('resize', checkScrollability);
    };
  }, [scenarios]);

  const handleScroll = (direction) => {
    const container = tableScrollRef.current;
    if (!container) return;

    const scrollAmount = 200;
    const scrollOptions = { behavior: 'smooth' };

    switch (direction) {
      case 'left':
        container.scrollBy({ left: -scrollAmount, ...scrollOptions });
        break;
      case 'right':
        container.scrollBy({ left: scrollAmount, ...scrollOptions });
        break;
      case 'up':
        container.scrollBy({ top: -scrollAmount, ...scrollOptions });
        break;
      case 'down':
        container.scrollBy({ top: scrollAmount, ...scrollOptions });
        break;
      default:
        break;
    }
  };

  async function fetchScenarios() {
    try {
      const { data, error } = await supabase
        .from('scenarios')
        .select('*')
        .order('scenarioid', { ascending: false })
        .order('order', { ascending: true });
  
      if (error) throw error;
  
      const formattedData = data.map(scenario => ({
        ...scenario,
        tags: formatTagsForDisplay(scenario.tags)
      }));
  
      setScenarios(formattedData);
    } catch (error) {
      console.error('Error fetching scenarios:', error.message);
      setError(`Failed to fetch scenarios: ${error.message}`);
    }
  }

  const handleExportCSV = (data) => {
    const csvContent = convertToCSV(data);
    downloadCSV(csvContent, `scenarios_export_${new Date().toISOString().split('T')[0]}.csv`);
  };

  const convertToCSV = (data) => {
    const headers = columns.map(col => col.name).join(',');
    const rows = data.map(row => 
      columns.map(col => `"${(row[col.name] || '').toString().replace(/"/g, '""')}"`).join(',')
    );
    return [headers, ...rows].join('\n');
  };

  const downloadCSV = (csvContent, filename) => {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handlePaste = async (e) => {
    e.preventDefault();
    setIsPasting(true);
    setPasteSuccess(false);
    
    try {
      const pasteData = e.clipboardData.getData('text');
      const rows = pasteData.split('\n').filter(row => row.trim() !== '');
      
      if (rows.length === 0) {
        setError('No valid data found in clipboard');
        return;
      }

      const newScenarios = rows.map(row => {
        const values = row.split('\t');
        return columns.reduce((obj, column, index) => {
          if (column.name === 'scenarioid' || column.name === 'order') {
            obj[column.name] = parseInt(values[index]) || null;
          } else {
            obj[column.name] = values[index] || '';
          }
          return obj;
        }, {});
      });

      const { data, error } = await supabase
        .from('scenarios')
        .insert(newScenarios)
        .select();

      if (error) throw error;

      setScenarios(prev => [...data, ...prev]);
      setError(null);
      setPasteSuccess(true);
      
      await logAudit('paste_scenarios', `Pasted ${newScenarios.length} scenarios`);
      
      setTimeout(() => {
        setPasteSuccess(false);
      }, 3000);
      
    } catch (error) {
      console.error('Error adding pasted scenarios:', error.message);
      setError(`Failed to add pasted scenarios: ${error.message}`);
    } finally {
      setIsPasting(false);
    }
  };

  function handleInputChange(e, id, field) {
    const { value } = e.target;
    
    if (field === 'tags') {
      setTagError('');
    }
    
    if (id === 'new') {
      setNewScenario(prev => ({ ...prev, [field]: value }));
    } else {
      setScenarios(prev =>
        prev.map(scenario =>
          scenario.id === id ? { ...scenario, [field]: value } : scenario
        )
      );
    }
  }

  async function handleBlur(id, field) {
    if (id === 'new') return;
  
    try {
      const scenarioToUpdate = scenarios.find(s => s.id === id);
  
      if (field === 'tags') {
        const tagsArray = parseTagsFromString(scenarioToUpdate.tags);
        const invalidTags = tagsArray.filter(tag => !tag.startsWith('#'));
        if (invalidTags.length > 0) {
          setTagError('All tags must start with # symbol');
          return;
        }
  
        const { error: updateError } = await supabase
          .from('scenarios')
          .update({ tags: tagsArray })
          .eq('id', id);
  
        if (updateError) throw updateError;
      } else {
        const { error: updateError } = await supabase
          .from('scenarios')
          .update({ [field]: scenarioToUpdate[field] })
          .eq('id', id);
  
        if (updateError) throw updateError;
      }
  
      await logAudit('update_field', `Updated ${field} for scenario ${id}`);
    } catch (error) {
      console.error('Error updating scenario:', error.message);
      setError(`Failed to update scenario: ${error.message}`);
    }
  }

  async function handleAddNewScenario() {
    try {
      const { data, error: insertError } = await supabase
        .from('scenarios')
        .insert([newScenario])
        .select();
  
      if (insertError) throw insertError;
  
      setScenarios(prev => [data[0], ...prev]);
      setNewScenario({});
      await logAudit('add_scenario', `Added new scenario ${data[0].id}`);
    } catch (error) {
      console.error('Error adding new scenario:', error.message);
      setError(`Failed to add new scenario: ${error.message}`);
    }
  }

  async function logAudit(action, details) {
    try {
      const { error } = await supabase
        .from('audit_log')
        .insert({ 
          user_email: user.email, 
          action, 
          details 
        });
      if (error) throw error;
    } catch (error) {
      console.error('Error logging audit:', error.message);
    }
  }

  const parseTagsFromString = (tagsString) => {
    if (!tagsString) return [];
    return tagsString.split(',')
      .map(tag => tag.trim())
      .filter(tag => tag !== '')
      .map(tag => tag.startsWith('#') ? tag : `#${tag}`);
  };
  
  const formatTagsForDisplay = (tagsArray) => {
    if (!tagsArray || !Array.isArray(tagsArray)) return '';
    return tagsArray.join(', ');
  };

  return (
    <div className="datasheet-view">
      <div className="header-container">
        <h1>Scenarios Datasheet</h1>
        <div className="user-controls">
          <span className="user-info">Welcome, {user?.fullName}</span>
          <div className="button-group">
            <button 
              onClick={async () => {
                handleExportCSV(scenarios);
                await logAudit('export_csv', 'Exported scenarios to CSV');
              }}
              className="export-button"
            >
              Export All to CSV
            </button>
            <Link to="/home" className="home-link">Switch to Home View</Link>
            <button className="logout-button" onClick={logout}>
              Logout
            </button>
          </div>
        </div>
      </div>

      {user?.email && (
        <div className="generator-container">
          <div className="generator-buttons">
            {(user.email === 'jim.bressoud@gmail.com' || user.fullName === 'James Bressoud') && (
              <ClaudeScenarioGenerator 
                supabase={supabase} 
                user={user} 
                onGenerated={fetchScenarios}
              />
            )}
          </div>
        </div>
      )}

      <div className="table-container">
        {error && (
          <div className="error-message">{error}</div>
        )}
        
        {isPasting && (
          <div className="paste-indicator">Processing paste data...</div>
        )}
        
        {pasteSuccess && (
          <div className="paste-success">Data successfully pasted!</div>
        )}
        
        <div 
          className="table-scroll-container" 
          onPaste={handlePaste} 
          tabIndex="0" 
          ref={tableScrollRef}
        >
          <table className="scenarios-table">
            <thead>
              <tr>
                {columns.map(column => (
                  <th 
                    key={column.name} 
                    style={{ width: column.width, minWidth: column.width }}
                  >
                    {column.label}
                  </th>
                ))}
                <th style={{ width: '100px' }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr className="new-row">
                {columns.map(column => (
                  <td key={column.name}>
                    <input
                      type="text"
                      value={newScenario[column.name] || ''}
                      onChange={(e) => handleInputChange(e, 'new', column.name)}
                      placeholder={`New ${column.label}`}
                    />
                  </td>
                ))}
                <td>
                  <button 
                    onClick={handleAddNewScenario} 
                    className="add-button"
                  >
                    Add
                  </button>
                </td>
              </tr>
              {scenarios.map(scenario => (
                <tr key={scenario.id}>
                  {columns.map(column => (
                    <td key={`${scenario.id}-${column.name}`}>
                      {column.name === 'tags' ? (
                        <div className="tags-input-container">
                          <input
                            type="text"
                            value={scenario[column.name] || ''}
                            onChange={(e) => handleInputChange(e, scenario.id, column.name)}
                            onBlur={() => handleBlur(scenario.id, column.name)}
                            placeholder="Enter tags starting with # and separated by commas"
                            className="tags-input"
                          />
                          {tagError && <div className="tag-error">{tagError}</div>}
                          <div className="tag-help">
                            Format: #TagName , #AnotherTag (start with # and separate with commas)
                          </div>
                        </div>
                      ) : (
                        <input
                          type={column.name === 'dateadded' ? 'date' : 'text'}
                          value={scenario[column.name] || ''}
                          onChange={(e) => handleInputChange(e, scenario.id, column.name)}
                          onBlur={() => handleBlur(scenario.id, column.name)}
                        />
                      )}
                    </td>
                  ))}
                  <td></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Scroll Controls */}
      <div className="scroll-controls">
        <div className="scroll-buttons">
          <button
            className="scroll-button"
            onClick={() => handleScroll('up')}
            disabled={!canScroll.up}
            aria-label="Scroll up"
          >
            ↑
          </button>
          <button
            className="scroll-button"
            onClick={() => handleScroll('down')}
            disabled={!canScroll.down}
            aria-label="Scroll down"
          >
            ↓
          </button>
        </div>
        <div className="horizontal-scroll-buttons">
          <button
            className="scroll-button"
            onClick={() => handleScroll('left')}
            disabled={!canScroll.left}
            aria-label="Scroll left"
          >
            ←
          </button>
          <button
            className="scroll-button"
            onClick={() => handleScroll('right')}
            disabled={!canScroll.right}
            aria-label="Scroll right"
          >
            →
          </button>
        </div>
      </div>
    </div>
  );
}

export default DatasheetView;