import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

function ProtectedRoute({ children }) {
  const { user } = useAuth();
  
  // Check both auth context and localStorage
  if (!user) {
    const storedUser = localStorage.getItem('userData');
    if (!storedUser) {
      return <Navigate to="/" replace />;
    }
  }

  return children;
}

export default ProtectedRoute;